<template>
  <v-sheet class="section-5" height="100%">
    <v-container class="fill-height" style="padding: 10rem 0">
      <div class="content-wrapper fill-width d-flex flex-column flex-lg-row justify-space-between align-center">
        <div class="left mb-10">
          <h2 class="text-center display-2 display-md-3 font-weight-bold">Deux types de prestation</h2>
          <div class="section-text d-flex flex-column align-center align-md-start mt-10">
            <v-btn x-large color="primary mt-5">M'en dire plus</v-btn>
          </div>
        </div>
        <div class="right mt-10 mt-lg-0">
          <div class="inner-wrapper">
            <div class="d-flex flex-column flex-md-row align-center justify-center">
              <div class="text">
                <v-subheader class="text-h6">Le Transfert Aéroport</v-subheader>
              </div>
              <div class="image">
                <v-img
                  :aspect-ratio="16/9"
                  :width="imageWidth"
                  src="~@/assets/illustrations/icone04.png"
                ></v-img>
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row align-center justify-center" style="margin-top: 7.5rem">
              <div class="text order-1 order-md-2">
                <v-subheader class="text-h6">La mise à disposition</v-subheader>
              </div>
              <div class="image order-2 order-md-1">
                <v-img
                  :aspect-ratio="16/9"
                  :width="imageWidth"
                  src="~@/assets/illustrations/icone05.png"
                ></v-img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {

  data() {
    return {
      width: 720,
    }
  },

  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '300px'
        case 'sm': return '300px'
        case 'md': return '300px'
        case 'lg': return '300px'
        case 'xl': return '300px'
      }
    }
  }

}
</script>

<style>

.section-5 {
  max-width: 1920px;
  margin: 0 auto;
}

</style>
<template>
  <v-sheet class="section-3" height="100%">
    <v-container class="fill-height" style="padding: 10rem 0">
      <h1 class="text-center fill-width">Que des avantages avec Woto !</h1>
      <div class="content-wrapper mt-10 fill-height fill-width d-flex flex-column flex-md-row justify-space-between align-center">
        <div class="first d-flex flex-column align-center mt-10">
          <v-img
              class=""
              src="~@/assets/illustrations/icone01.png"
              :aspect-ratio="16/9"
              :width="imageWidth"
              contain
          ></v-img>
          <p class="mt-10" style="width: 250px">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas quos incidunt at? Atque alias deserunt laudantium iusto voluptates facilis animi labore, incidunt voluptatum blanditiis eius nemo modi possimus eum illo.</p>
        </div>
        <div class="second d-flex flex-column align-center mt-10">
          <v-img
              src="~@/assets/illustrations/icone02.png"
              :aspect-ratio="16/9"
              :width="imageWidth"
              contain
          ></v-img>
          <p class="mt-10 " style="width: 250px">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus culpa quod ex ab quo non nesciunt neque reprehenderit sapiente, voluptatibus explicabo aspernatur, dolore voluptatem nostrum cumque. Eveniet sint sunt velit?</p>
        </div>
        <div class="third d-flex flex-column align-center mt-10">
          <v-img
              class=""
              src="~@/assets/illustrations/icone03B.png"
              :aspect-ratio="16/9"
              :width="imageWidth"
              contain
          ></v-img>
          <p class="mt-10" style="width: 250px">Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam doloribus itaque optio nobis rerum consequuntur similique incidunt repudiandae explicabo, eligendi iusto sit! Voluptatem architecto omnis quas quidem soluta corrupti dignissimos?</p>
        </div>
      </div>
      <div class="d-flex justify-center fill-width mt-10">
        <v-btn color="primary" class="py-6 px-10">En savoir plus</v-btn>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {

  data() {
    return {
      width: 720,
    }
  },

  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '200px'
          case 'sm': return '250px'
          case 'md': return '250px'
          case 'lg': return '250px'
          case 'xl': return '250px'
        }
    }
  }

}
</script>

<style>

.section-3 {
  max-width: 1920px;
  margin: 0 auto;
}

</style>
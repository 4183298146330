<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

};
</script>

<style>

.fill-height {
  height: 100%;
}

.fill-width {
  width: 100%
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
    
    
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all .5s ease;
}
    
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

</style>

<template>
  <v-footer padless class="white">
      <v-card class="flex footer-inner" flat tile>
        <v-container>
          <v-card-title
            class="d-flex flex-column flex-md-row justify-md-space-between"
          >
            <div class="contact d-flex flex-column align-start">
              <v-btn
                v-for="item in contactList"
                :key="item.title"
                text
                class="white--text"
              >
                <v-icon small class="mr-2">{{ item.icon }}</v-icon>
                {{ item.title }}
              </v-btn>
            </div>

            <div class="socials mt-10">
              <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
                <v-icon size="24px">
                  {{ icon }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-divider dark></v-divider>
          <v-card-text class="py-2 white--text text-center">
            {{ new Date().getFullYear() }} - <strong>Woto</strong>
          </v-card-text>
        </v-container>
      </v-card>
    </v-footer>
</template>

<script>
export default {
  name: 'Footer',

  data() {
    return {
      contactList: [
        {
          title: "contact@woto.sn",
          icon: "mdi-at",
          link: "",
        },
        {
          title: "770000000",
          icon: "mdi-card-account-phone",
          link: "",
        },
        {
          title: "Address to woto office",
          icon: "mdi-map-marker",
          link: "",
        },
      ],
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    }
  }

}
</script>

<style scoped>

.footer-inner {
  max-width: 1920px;
  height: 575px;
  margin: 0 auto;
  background-color: teal;
  color: white;
  padding: 0px 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
    url("../../assets/illustrations/grande-img-03.jpg");
  background-size: cover;
  background-position: center;
}

</style>
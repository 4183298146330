import * as fb from '../firebase'

const state = {
}

const mutations = {

}

const actions = {

  getVans({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let vanArray= []

        const van = await fb.db.collection('van').get()

        van.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Van does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id
          tmpObject.brandModel = `${doc.data().brand} ${doc.data().model}`

          vanArray.push(tmpObject)
        })

        return resolve(vanArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getDriverById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {


        const doc = await fb.db.doc(`driver/${payload.id}`).get()

        if(doc.id) {

          let tmpObject = doc.data()
          tmpObject.id = doc.id
          tmpObject.fullName = `${doc.data().firstName} ${doc.data().lastName}`

          return resolve(tmpObject)
        } else {
          return resolve({ code: 2, message: 'Driver does not exist!' })
        }

      } catch (error) {
        return resolve(error)
      }
    })
  },

  async getOrderByStatusDateVanId({ commit }, payload) {
    try {

      let orderArray = []

      const order = await fb.db.collection('order').where('payment.date', '==', payload.date).where('tripStatus', '==', payload.status).where('vanRef', '==', payload.vanId).get()

      order.forEach(doc => {
        let tmpObject = doc.data()

        orderArray.push(tmpObject)
      })

      return Promise.resolve(orderArray)
      
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import Vue from 'vue'
import Vuex from 'vuex'

import order from './order'
import * as fb from '../firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedInCustomer: null,
    newOrder: {},
  },
  mutations: {
    SET_LOGGED_IN_USER(state, payload) {
      state.loggedInCustomer = payload
    },
    SET_ORDER(state, payload) {
      state.newOrder = payload;
    },
  },
  actions: {

    async loginWithFirebase({ state, commit }, payload) {
      try {

        const login = await fb.auth.signInWithEmailAndPassword(payload.email, payload.password)

        const customer = await fb.db.collection('customer').where('uid', "==", login.user.uid).limit(1).get()

        customer.forEach(doc => {
          if(!doc.exists) return Promise.reject({ code: 2, message: 'User does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          commit('SET_LOGGED_IN_USER', tmpObject)
        })

        console.log(state.loggedInCustomer)

        localStorage.setItem('loggedInCustomer', JSON.stringify(state.loggedInCustomer))

        return Promise.resolve({ code: 1 })
        
      } catch (error) {
        return Promise.reject(error)
      }
    },

    logoutFromFirebase({ commit }) {
      return new Promise((resolve, reject) => {
        fb.auth.signOut().then(() => {
          const payload = null
          commit("SET_LOGGED_IN_USER", payload)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },


    getVans({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
  
          let vanArray= []
  
          const van = await fb.db.collection('van').get()
  
          van.forEach(doc => {
            if(!doc.exists) return resolve({ code: 2, message: 'Van does not exist!' })
  
            let tmpObject = doc.data()
            tmpObject.id = doc.id
            tmpObject.brandModel = `${doc.data().brand} ${doc.data().model}`
  
            vanArray.push(tmpObject)
          })
  
          return resolve(vanArray)
  
        } catch (error) {
          return resolve(error)
        }
      })
    },

    getDriverById({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
  
  
          const doc = await fb.db.doc(`driver/${payload.id}`).get()
  
          if(doc.id) {
  
            let tmpObject = doc.data()
            tmpObject.id = doc.id
            tmpObject.fullName = `${doc.data().firstName} ${doc.data().lastName}`
  
            return resolve(tmpObject)
          } else {
            return resolve({ code: 2, message: 'Driver does not exist!' })
          }
  
        } catch (error) {
          return resolve(error)
        }
      })
    },

    createOrder({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
  
          commit("SET_ORDER", payload)

          console.log(state.order)
  
          const order = await fb.db.collection('order').add(state.order)
          console.log(order)
  
          const updateDocId = await fb.db.doc(`order/${order.id}`).update({
            docId: order.id
          })
          console.log(updateDocId)
  
          return resolve({ code: 1 })
        } catch (error) {
          return resolve(error)
        }
      })
    },

    async getParameters({ commit, state }) {
      let parameter = await fb.db.collection('parameters').limit(1).get()
      let tmpObject = {}
      parameter.forEach(doc => {
        tmpObject = doc.data()
        tmpObject.id = doc.id
      })
      return Promise.resolve(tmpObject)
    },
  },
  modules: {
    order
  }
})

<template>
  <div>
    <div class="section-wrapper">
      <section-1 />
    </div>
    <div class="section-wrapper">
      <section-2 />
    </div>
    <div class="section-wrapper">
      <section-3 />
    </div>
    <div class="section-wrapper">
      <section-4 />
    </div>
    <div class="section-wrapper">
      <section-5 />
    </div>
    <div class="section-divider section-1">
      <v-card color="secondary" class="py-10">
        <v-card-text class="text-center display-1 primary--text">ON EST BIEN CHEZ WOTO</v-card-text>
      </v-card>
    </div>
    <div class="section-wrapper">
      <section-6 />
    </div>
  </div>
</template>

<script>

import Section1 from '@/components/public/home-sections/Section1.vue';
import Section2 from '@/components/public/home-sections/Section2.vue';
import Section3 from '@/components/public/home-sections/Section3.vue';
import Section4 from '@/components/public/home-sections/Section4.vue';
import Section5 from '@/components/public/home-sections/Section5.vue';
import Section6 from '@/components/public/home-sections/Section6.vue';

export default {
  name: "Home",
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6
  }
}
</script>

<template>
  <v-sheet class="section-2" height="100%">
    <v-container class="fill-height" style="padding: 10rem 0">
      <div class="content-wrapper fill-height d-flex flex-column flex-md-row align-center">
        <div class="left">
          <div class="content-text" :class="$vuetify.breakpoint.lgAndUp ? 'width-70' : ''">
            <h1>WOTO !</h1>

            <p class="mt-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem vero repudiandae, necessitatibus modi nostrum ex in! Id perferendis accusamus laudantium, esse quae voluptatum earum! Dolorem facilis cupiditate nostrum laboriosam eos.</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero in rem magnam exercitationem ad maxime doloribus, omnis sed quae reiciendis tempore tenetur, porro quis aliquam facere, asperiores nihil! Iusto, amet?</p>

            <v-subheader class="subtitle-2 font-weight-bold">(+WOTO est né pour vous transporter)</v-subheader>
          </div>
          <v-btn color="primary" class="mt-5 mt-md-10">Notre offre</v-btn>
        </div>
        <div class="right mt-10 mt-md-0">
          <v-img
            :aspect-ratio="16/9"
            :width="imageWidth"
            src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
          ></v-img>
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {

  data() {
    return {
    }
  },

  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '200px'
        case 'sm': return '450px'
        case 'md': return '600px'
        case 'lg': return '800px'
        case 'xl': return '800px'
      }
    }
  }

}
</script>

<style>


.width-70 {
  width: 70%;
}

.section-2 {
  max-width: 1920px;
  margin: 0 auto;
}

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PublicPage from '../views/PublicPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Public-Page',
    redirect: '/fr/',
    component: PublicPage,
    children: [
      {
        path: '/fr/',
        name: 'Home',
        component: Home
      },
      {
        path: '/fr/nous/offre',
        name: 'Offre',
        component: () => import(/* webpackChunkName: "nous-offre" */ '@/views/nous/Offre.vue')
      },
      {
        path: '/fr/nous/avantages',
        name: 'Avantages',
        component: () => import(/* webpackChunkName: "nous-avantages" */ '@/views/nous/Avantages.vue')
      },
      {
        path: '/fr/nous/valeurs',
        name: 'Valeurs',
        component: () => import(/* webpackChunkName: "nous-valeurs" */ '@/views/nous/Valeurs.vue')
      },
      {
        path: '/fr/nous/flotte',
        name: 'Flotte',
        component: () => import(/* webpackChunkName: "nous-flotte" */ '@/views/nous/Flotte.vue')
      },
      {
        path: '/fr/nous/chauffeurs',
        name: 'Chauffeurs',
        component: () => import(/* webpackChunkName: "nous-chauffeurs" */ '@/views/nous/Chauffeurs.vue')
      },
      {
        path: '/fr/services/types-de-prestation',
        name: 'Types',
        component: () => import(/* webpackChunkName: "services-types" */ '@/views/services/Types.vue')
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/home',
    name: 'Client-Home',
    redirect: '/home/trip/',
    component: () => import(/* webpackChunkName: "customer-home" */ '../views/customer/Customer.vue'),
    children: [
      {
        path: '/home/trip/',
        name: 'Client-Trip',
        component: () => import(/* webpackChunkName: "home-trip" */ '../views/customer/Home.vue'),
      },
      {
        path: '/home/updatedOrder/',
        name: 'UpdatedOrder',
        component: () => import(/* webpackChunkName: "register" */ '../views/customer/UpdatedNewOrder.vue')
      },
      {
        path: '/home/profile/',
        name: 'Client-Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/customer/Profile.vue'),
      },
      {
        path: '/home/order-status/:id',
        name: 'Client-Order-Status',
        component: () => import(/* webpackChunkName: "order-status" */ '../views/customer/OrderCreated.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div>
    <v-card>
      <navbar />
    </v-card>
    <v-main>
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/public/Navbar.vue";
import Footer from "@/components/public/Footer.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
  },
}
</script>

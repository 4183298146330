<template>
  <v-sheet class="section-4" height="100%">
    <v-container class="fill-height" style="padding: 10rem 0">
      <div class="content-wrapper d-flex flex-column flex-lg-row">
        <div class="left mb-10">
          <h1 class="text-center">Pour toutes vos exigences professionnelles</h1>
          <div class="inner-content-wrapper d-flex flex-column flex-md-row align-center mt-10 pt-10">
            <div class="section-img mr-md-10">
              <v-img
                :aspect-ratio="1"
                :width="imageWidth"
                src="~@/assets/illustrations/illmoy-05.jpg"
              ></v-img>
            </div>
            <div class="section-text d-flex flex-column align-center align-md-end align-lg-start mt-10">
              <p class="text-center text-md-end text-lg-start">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime libero quod exercitationem laudantium. Sapiente obcaecati, tenetur aperiam, repellendus sequi excepturi assumenda odio nisi accusamus corporis alias rem consequatur eum. Saepe!</p>
              <v-btn color="primary mt-5">Compte pro</v-btn>
            </div>
          </div>
        </div>
        <div class="right mt-10 mt-lg-0">
          <h1 class="text-center">Pour tous vos événements personnels</h1>
          <div class="inner-content-wrapper d-flex flex-column flex-md-row align-center mt-10 pt-10">
            <div class="section-img mr-md-10 mr-lg-0 ml-lg-10 order-1 order-lg-2">
              <v-img
                :aspect-ratio="1"
                :width="imageWidth"
                src="~@/assets/illustrations/illmoy-06.jpg"
              ></v-img>
            </div>
            <div class="section-text order-1 order-lg-1 d-flex flex-column align-center align-md-end mt-10">
              <p class="text-center text-md-end">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime libero quod exercitationem laudantium. Sapiente obcaecati, tenetur aperiam, repellendus sequi excepturi assumenda odio nisi accusamus corporis alias rem consequatur eum. Saepe!</p>
              <v-btn color="primary mt-5">Réservation</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {

  data() {
    return {
      width: 720,
    }
  },

  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '200px'
        case 'sm': return '300px'
        case 'md': return '400px'
        case 'lg': return '400px'
        case 'xl': return '400px'
      }
    }
  }

}
</script>

<style>

.section-4 {
  max-width: 1920px;
  margin: 0 auto;
}

</style>
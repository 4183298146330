<template>
  <div>
    <v-navigation-drawer v-model="drawer" app right temporary>
      <v-list nav dense>
        <v-list-group class="my-1">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Nouse</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link to="/fr/nous/offre/" class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">Offre</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/fr/nous/avantages/" class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2"
                >Avantages</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/fr/nous/valeurs/" class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">Valeurs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/fr/nous/flotte/" class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">Flotte</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/fr/nous/chauffeurs/" class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2"
                >Chauffeurs</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item link class="my-1">
          <v-list-item-content>
            <v-list-item-title class="subtitle-2"
              >Reservation</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-group class="my-1">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Services</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link to="/fr/services/types-de-prestation/" class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2"
                >Types de prestation</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2"
                >Compte professionnel</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider class="mt-5 mb-8"></v-divider>
      <div class="btn-wrapper px-3">
        <v-btn block color="primary" :to="'/login'">Se connecter</v-btn>
      </div>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-container>
        <div
          v-if="$vuetify.breakpoint.smAndDown ? true : false"
          class="mobile-nav d-flex flex-row justify-space-between align-center"
        >
          <v-img
            @click="goHome"
            src="~@/assets/symbol.svg"
            min-height="50"
            max-width="50"
            contain
          ></v-img>
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </div>
        <div class="main-nav d-flex flex-row justify-space-between align-center" v-else>
          <v-toolbar-title class="pointer-img">
            <v-img
              @click="goHome"
              src="~@/assets/symbol.svg"
              min-height="75"
              max-width="75"
              contain
            ></v-img>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <div class="links-wrapper">
            <v-menu transition="scroll-y-transition" open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                plain
                text
              >
                Nous <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list style="width: 200px">
              <v-list-item link v-for="item in menuList" :key="item.id" :to="item.link">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn plain text class="mr-3"> Réservation </v-btn>

          <v-menu transition="scroll-y-transition" open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                plain
                text
              >
                Services <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list style="width: 200px">
              <v-list-item link v-for="item in menuList2" :key="item.id" :to="item.link">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn color="primary" elevation="0" :to="'/login'">
            Se connecter
          </v-btn>
          </div>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      menuList: [
        {
          id: 1,
          title: "Offre",
          link: "/fr/nous/offre/",
        },
        {
          id: 2,
          title: "Avantages",
          link: "/fr/nous/avantages/",
        },
        {
          id: 3,
          title: "Valeurs",
          link: "/fr/nous/valeurs/",
        },
        {
          id: 4,
          title: "Flotte",
          link: "/fr/nous/flotte/",
        },
        {
          id: 5,
          title: "Chauffeurs",
          link: "/fr/nous/chauffeurs/",
        },
      ],
      menuList2: [
        {
          id: 1,
          title: "Types de prestation",
          link: "/fr/services/types-de-prestation/",
        },
        {
          id: 2,
          title: "Compte professionnel ",
          link: "/fr/nous/avantages/",
        }
      ],
    }
  },

  methods: {
    goHome() {
      this.$router.push('/fr/')
    }
  }
};
</script>

<style>

.pointer-img {
  cursor: pointer;
}

</style>

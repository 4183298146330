import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#F6A02A',
        accent: '#4C37F4',
        secondary: '#000000',
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: '#F33A36',
        success: '#4CAF50'
      }
    }
  }
})

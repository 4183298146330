<template>
  <v-sheet class="section-1" height="90vh">
    <v-container class="fill-height">
      <div class="content-wrapper fill-width fill-height d-flex flex-row justify-end align-center">
        <v-card class="right yellow lighten-5" shaped elevation="4">
          <v-card-text class="d-flex flex-row align-center pa-10">
            <div class="timeline-wrapper">
              <v-timeline align-top>
                <v-timeline-item :icon="'mdi-home'"></v-timeline-item>
                <v-timeline-item :icon="'mdi-map-marker'"></v-timeline-item>
              </v-timeline>
            </div>
            <div class="right mr-8">
              <div class="radio-wrapper">
                <v-radio-group v-model="tripReason">
                  <v-radio
                    v-for="item in radioItems"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                    color="primary"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div class="call-to-action mt-3">
                <v-btn color="primary">Réservez votre van</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      tripReason: "",
      radioItems: [
        {
          id: 1,
          title: "Mise à disposition",
        },
        {
          id: 2,
          title: "Transfert vers l'aéroport",
        },
        {
          id: 3,
          title: "Transfert depuis l'aéroport",
        },
      ],
    };
  },
};
</script>

<style>

.rounded-card {
  border-radius: 24px;
}

.section-1 {
  max-height: 1080px;
  max-width: 1920px;
  margin: 0 auto;

  background-image: url("../../../assets/illustrations/grande-img-01.jpg");
  background-size: cover;
  background-position: center;
}


</style>

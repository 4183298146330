<template>
  <v-sheet class="section-5" height="100%">
    <v-container class="fill-height" style="padding: 10rem 0">
      <div class="main-image-wrapper fill-width d-flex flex-row align-center justify-center">
        <v-img
          :aspect-ratio="16 / 9"
          src="~@/assets/illustrations/grande-img-02.jpg"
        ></v-img>
      </div>
      <div
        class="content-wrapper fill-width d-flex flex-column align-center" style="margin-top: 5rem"
      >
        <div class="first mb-10 d-flex flex-column flex-md-row align-center justify-space-between">
          <div class="content-text" style="width: 50%">
            <h2 class="text-center display-1 display-md-3 font-weight-bold text-md-start">
              NOS VALEURS
            </h2>
            <p class="mt-5 text-center text-md-start">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum cumque maxime modi ea, earum odit sunt natus beatae consectetur?</p>
          </div>
          <div class="content-image mt-10 d-flex align-center">
            <v-img
              src="~@/assets/illustrations/illmoy-07.jpg"
              :aspect-ratio="1"
              :width="imageWidth"
              contain
            ></v-img>
          </div>
        </div>
        <div class="second mb-10 d-flex flex-column flex-md-row align-center justify-space-between">
          <div class="content-text order-1 order-md-2" style="width: 50%">
            <h2 class="text-center display-1 display-md-3 font-weight-bold text-md-end">
              NOTRE FLOTTE
            </h2>
            <h3 class="text-center text-h5 font-weight-bold text-md-end mt-10">Volkswagen Caravelle, le Van Idéal !</h3>
            <p class="mt-5 text-center text-md-end">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum cumque maxime modi ea, earum odit sunt natus beatae consectetur?</p>
          </div>
          <div class="content-image mt-10 px-10 d-flex align-center order-2 order-md-1">
            <v-img
              src="~@/assets/illustrations/illmoy-08.jpg"
              :aspect-ratio="1"
              :width="imageWidth"
              contain
            ></v-img>
          </div>
        </div>
        <div class="third mb-10 d-flex flex-column flex-md-row align-center justify-space-between">
          <div class="content-text" style="width: 50%">
            <h2 class="text-center display-1 display-md-3 font-weight-bold text-md-start">
              NOS CHAUFFEURS
            </h2>
            <p class="mt-5 text-center text-md-start">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae illum aliquid quae ut fuga! Eum, in molestias! Numquam ipsum cum cumque maxime modi ea, earum odit sunt natus beatae consectetur?</p>
          </div>
          <div class="content-image mt-10 px-10 d-flex align-center">
            <v-img
              src="~@/assets/illustrations/illmoy-09.jpg"
              :aspect-ratio="1"
              :width="imageWidth"
              contain
            ></v-img>
          </div>
        </div>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      width: 720,
    };
  },

  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300px";
        case "sm":
          return "300px";
        case "md":
          return "400px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
      }
    },
  },
};
</script>

<style>
.section-5 {
  max-width: 1920px;
  margin: 0 auto;
}
</style>
